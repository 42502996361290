import React, { useState } from 'react'
import './userstyle.css';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import makeRequest from '../../helpers/http-request';
import Loader from '../../components/Loader';
const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const [error, setError] = useState();
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [password, setPassword] = useState({
        newPassword:"",
        confirmPassword: ""
    });
    const [showPassword, setShowPassword] = useState(false);
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    const storedPassword = localStorage.getItem("passwordResetEmail") || "";

    const baseAPI = "https://us-east1-recruiterportal.cloudfunctions.net/user_authentication_api_v3";

    const handleEmailSubmit = async () => {
        try{
            if(!email){
                setError("Email is required");
                return;
            }

            setError("");
            setLoader(true);
            localStorage.setItem("passwordResetEmail", email);

            const options = {
                url: baseAPI,
                method : 'POST',
                data : {
                     action: "send-password-reset",
                     email 
                } 
            }
    
            const res = await makeRequest(options);

            console.log(res);
            
            if(res?.status == 200){
                setEmail("");
                setEmailSent(true);
            }else{
                setError(res?.response?.data?.message);
            }
        }catch(err){
            console.log(err);
        }
    }

    const handlePasswordSubmit = async () => {
        try{
            if(!password.newPassword || !password.confirmPassword){
                setError("Both passwords are required");
                return;
            }
            
            if(password.newPassword != password.confirmPassword){
                setError("Both passwords should be same");
                return;
            }
    
            setLoader(true);
    
            const options = {
                url : baseAPI,
                method:'POST',
                data : {
                    action: "update-password",
                    email : storedPassword,
                    new_password : password.newPassword,
                    token : token
                }
            }
    
            const res = await makeRequest(options);
    
            if(res?.status == 200){
                setLoader(false);
                setSuccess(true);

                setTimeout(()=>{
                    navigate("/")
                },3000);

            }else{
                setError(res?.response?.data?.message);
            }    
        }catch(err){
            console.log(err);
        }
    }

    const handlePasswordChange = (e) =>{
        setPassword((prev)=>{
            return {
                ...prev,
                [e.target.name]  : e.target.value.replace(/\s+/g, '')
            }
        })
     }

  return (
    <div className='reset-main row-flex justify-center align-center'>
        {token ? (success ? (<div className='reset-container col-flex'>
            <div className='w-100 text-container'>
            <h3 className='text-center heading-3 text-underline'>Password reset successful.</h3>
            <p className='text-3'>Navigating to login</p>
            </div>
            </div>) : 
            (<div className='reset-container col-flex'>
            <div className='w-100 text-container'>
            <h3 className='text-center heading-3 text-underline'>Reset your password</h3>
            </div>
                <div className='w-100 inputField'>
                <label htmlFor='reset-email'>New Password</label>
                <input className='w-100 reset-input' id="reset-email" name="newPassword" type={showPassword ? "text" : "password"} placeholder='Enter password' onChange={(e)=>handlePasswordChange(e)}/>
            </div>
            <div className='w-100 inputField'>
                <label htmlFor='reset-email'>Confirm Password</label>
                <input className='w-100 reset-input' id="reset-email" name="confirmPassword" type={showPassword ? "text" : "password"} placeholder='Re-enter password' onChange={(e)=>handlePasswordChange(e)}/>
                {error &&<span className='error'>{error}</span>}
            </div>
            <div className='w-100 text-end bg-transparent'>
            <p className='text-3 cursor-pointer' onClick={()=>setShowPassword(!showPassword)}>{showPassword ? "Hide password" : "Show password" }</p>
            </div>
            <button className='reset-btn cursor-pointer' onClick={handlePasswordSubmit}>
                {loader ? <Loader/> : "Reset Password"}
            </button>
        </div>)) : (<div className='reset-container col-flex'>
            {emailSent ? ( <div>
                <h3 className='heading-3'>Reset link sent to your email</h3>
            </div>) : (<>
                <div className='w-100 text-container'>
                    <h3 className='text-center heading-3 text-underline'>Forgot your password?</h3>
                    <p className='text-3'>Enter your email to reset it</p>
                </div>
                <div className='w-100 inputField'>
                    <label htmlFor='reset-email'>Email</label>
                    <input className='w-100 reset-input' id="reset-email" name="resetPassword" type="email" placeholder='Enter your email' onChange={(e)=>setEmail(e.target.value.replace(/\s+/g, ''))}/>
                    {error &&<span className='error'>{error}</span>}
                </div>

            <button className='reset-btn cursor-pointer' onClick={handleEmailSubmit}>{loader ? <Loader/> : "Reset Password"}</button>
            <Link to="/">
            <p className='text-3 cursor-pointer'>Back to home page</p> 
            </Link>
            </>)}
        </div>)}
    </div>
  )
}

export default ResetPassword
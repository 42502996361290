import React, { useState, useEffect } from 'react';
import Dashnav from '../../components/dashnav';
import { Link, useNavigate } from "react-router-dom";
import Info from "../../images/info.svg";
import Sidenav from "../../images/side_top.svg";
import General from "../../images/general.svg";
import Others from "../../images/others.svg";
import saveTick from "../../images/saveTick.svg";
import { useAuth } from '../../authContext';
import DatePicker from 'react-datepicker';
import { format, parseISO } from "date-fns";
import { FaCalendarAlt } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './registerIntv.css';


const EditActiveInterview = () => {
    const {user} = useAuth();

    const navigate = useNavigate();

    function backEditCandidate() {
        navigate('/activeInterviews');
    }
    // const { isLoggedIn, login, user, choosenCandidate,
    //     setChoosenCandidate, setCandidate, candidateList, setCandidateList, candidateId, setCandidateId } = useAuth();

    const [recruitersList, setRecruitersList] = useState([]); // State for recruiters list
    const [recruiter, setRecruiter] = useState("");
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
      candidate_full_name: "",
    candidate_email_id: "",
    from_email: ""
    });
    const [isEditing, setIsEditing] = useState(false);
    const [recruitersBList, setBRecruitersList] = useState([]); // State for recruiters list
    const [candidateDetails, setCandidateDetails] = useState([]);
    const [candidateList, setCandidateList] = useState([]);
    const [choosenCandidate, setChoosenCandidate] = useState("");
    const [existingCendidate, setexistingCandidate] = useState("");


    useEffect(() => {
        // Fetch candidates data on component mount
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        let storedUser = JSON.parse(localStorage.getItem('user'));

        console.log("urlParams", urlParams.get('id'));

        fetch('https://us-east1-recruiterportal.cloudfunctions.net/Fetch_Candidate_Interview_Details_v3', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                emailid: user.email,
                operation: 'retrieve',
                from_email: storedUser.email,
                id: urlParams.get('id') // Use the retrieved ID as payload
            })
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                setCandidateDetails(data[0])
                setFormData(data)
                setChoosenCandidate(formData.candidate_full_name);
                console.log("after",formData);
                
                console.log(formData, "formData fetch data for update");
            })
            .catch(error => console.error('Error fetching candidates:', error));
        console.log("toast", toast);

    }, []);

    useEffect(()=>{
                setChoosenCandidate(formData.candidate_full_name);
                console.log(formData.candidate_full_name);
    },[formData])

    useEffect(() => {
        fetchCandidates();
    }, []);

    const fetchCandidates = async () => {
        try {
            let storedUser = JSON.parse(localStorage.getItem('user'));
            const queryObj = {
                emailid : user.email,
                "email": storedUser.email
            }
            console.log("Sending query:", queryObj); // Log the query object

            const response = await fetch("https://us-east1-recruiterportal.cloudfunctions.net/Fetch_Candidate_Details_Min_v2", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(queryObj)
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            setCandidateList(data);
            console.log("Received data:", data); // Log the received data
        } catch (error) {
            // setError(error.message);
            console.error("Error fetching jobs:", error); // Log the error
        }
    };


    useEffect(() => {
        const fetchRecruiters = async () => {
            try {
                const response = await fetch("https://us-east1-recruiterportal.cloudfunctions.net/Retrieve_Recruiter_Details_v3", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        emailid : user.email,
                        operation: "retrieve"
                    })
                });

                const data = await response.json();
                if (response.ok) {
                    // Extract unique recruiters list with their ID
                    const recruiters = data.data.map(item => ({ // Extract id
                        recruiter_alias_name: item.recruiter_alias_name || "Unknown"  // Extract assigned_recruiter or default to "Unknown"
                    }));

                    // Update state with the extracted data
                    setBRecruitersList(recruiters);
                    console.log("Recruiters List:", recruiters);
                } else {
                    console.error("Failed to fetch recruiters:", data.message);
                }
            } catch (error) {
                console.error("Error fetching recruiters:", error);
            }
        };

        fetchRecruiters();
    }, []);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <div className="custom-input" onClick={onClick} ref={ref}>
            <input
                type="text"
                value={value || ""}
                placeholder="mm/dd/yyyy"
                readOnly
                className="date-input"
            />
            <FaCalendarAlt className="calendar-icon" />
        </div>
    ));

    const handleDateChange = (date) => {
        const formattedDate = date ? format(date, "MM/dd/yyyy") : ""; // Format date as MM/DD/YYYY
        setFormData({ ...formData, submission_date: formattedDate });
        console.log("Updated Submission Date:", formattedDate); // For debugging
    };


    const CustomInputMarketing = React.forwardRef(({ value, onClick }, ref) => (
        <div className="custom-input" onClick={onClick} ref={ref}>
            <input
                type="text"
                value={value || ""}
                placeholder="mm/dd/yyyy hh:mm:ss"
                readOnly
                className="date-input"
            />
            <FaCalendarAlt className="calendar-icon" />
        </div>
    ));


    const handleDateChangeMarketing = (date) => {
        // Format the selected date and time as 'MM/dd/yyyy HH:mm:ss'
        const formattedDate = date ? format(date, "MM/dd/yyyy HH:mm:ss") : "";
        setFormData({ ...formData, interview_date_time: formattedDate });
        console.log("Updated Interview Date Time:", formattedDate);
    };


    const handleActiveIntv = async () => {

        try {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            // Log formData for debugging
            console.log(formData, "formData==========");

            // Prepare request payload
            const payload = {
                // emailid : user.email,
                operation: "update",
                id: urlParams.get('id'),
                candidate_full_name: `${formData.candidate_full_name || ''}`,
                candidate_email_id: `${formData.candidate_email_id || ''}`,
                from_email: `${formData.from_email || ''}`,
                rate: `${formData.rate || ''}`,
                submission_date: `${formData.submission_date || ''}`,
                submission_status: `${formData.submission_status || ''}`,
                technology: `${formData.technology || ''}`,
                to_email: `${formData.to_email || ''}`,
                client_name: `${formData.client_name || ''}`,
                comments: `${formData.comments || ''}`,
                interview_date_time: `${formData.interview_date_time || ''}`,
                interview_panel_members: `${formData.interview_panel_members || ''}`,
                interview_timezone: `${formData.interview_timezone || ''}`
            };

            // Send the request using fetch with async/await
            const response = await fetch('https://us-east1-recruiterportal.cloudfunctions.net/Fetch_Candidate_Interview_Details_v3', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            // Check if the response is okay (status 200-299)
            if (response.ok) {
                const data = await response.json();

                if (data.message) {
                    toast.success("Changes Saved Successfully", {
                        draggable: "true",
                        autoClose: 2000,
                        // position: "center",
                        className: "toast-center",
                    });

                    // navigate('/benchcandidates');
                } else {
                    toast.warn("Action completed successfully.", {
                        draggable: "true",
                        autoClose: 2000,
                        // position: "center",
                        className: "toast-center",
                    });  // Fallback message if `data.message` is undefined
                }
            } else {
                // Handle unsuccessful response
                // toast.warn(response.status);
                toast.error(`Failed to update. Status: ${response.status}`, {
                    draggable: "true",
                    autoClose: 2000,
                    // position: "center",
                    className: "toast-center",
                });
            }
        } catch (err) {
            // Handle any errors that occur during fetch or in the above code
            console.error("Error occurred during fetch:", err);
            toast.error(err, {
                draggable: "true",
                autoClose: 2000,
                // position: "center",
                className: "toast-center",
            })
        }
    };



    const handleCandidateChange = (e) => {
        const selectedEmail = e.target.value;
        setChoosenCandidate(selectedEmail);
        
        const selectedCandidate = candidateList.find(
            (item) => item.full_name === selectedEmail
        );
    
        if (selectedCandidate) {
            setFormData((prev) => ({
                ...prev,
                candidate_full_name: selectedCandidate.full_name,
                candidate_email_id: selectedCandidate.primary_email,
                from_email: selectedCandidate.assigned_recruiter || ""
            }));
        }
    };

    return (
        <div className="main-dash row-flex">
            <Dashnav />
            <div className='activeIntvRegister'>
                <div className='header-active'>
                    <img src={Sidenav} alt="" srcset="" onClick={backEditCandidate} />
                    <img src={Info} alt="" srcset="" />
                    <h2 className='title'>Modify Active Interviews</h2>
                </div>

                <div className='mainContent-activeIntv'>
                    <section>
                        <div className='legendHeading-activeIntv'>
                            <img src={General} alt="" srcset="" />
                            <h5>General</h5>
                            <span className='divider'></span>
                        </div>
                        <div className='gridActiveIntv'>

                        <div className='inputComponentActiveIntv'>
            <label htmlFor="">Candidate Full Name<span>*</span></label>
            <select className="" onChange={handleCandidateChange} value={choosenCandidate}>{console.log("choosenCandidate",choosenCandidate)}
                <option value="">Select Candidate</option>
                {candidateList.map((item) => (
                    <option key={item.primary_email} value={item.full_name}>

                        {item.full_name}
                    </option>
                ))}
            </select>
        </div>

        <div className='inputComponentActiveIntv'>
            <label htmlFor="">Candidate Email Id</label>
            <input 
                type="text" 
                value={formData.candidate_email_id} 
                onChange={(e) => setFormData((prev) => ({ ...prev, candidate_email_id: e.target.value }))} 
            />
        </div>

        <div className='inputComponentActiveIntv'>
            <label htmlFor="">Assigned Recruiter<span>*</span></label>
            <select 
                value={formData.from_email} 
                onChange={(e) => setFormData((prev) => ({ ...prev, from_email: e.target.value }))}>
                <option value="">Select Recruiter</option>
                {[...new Set(candidateList.map(item => item.assigned_recruiter).filter(Boolean))]
                    .map((recruiter, index) => (
                        <option key={index} value={recruiter}>{recruiter}</option>
                    ))}
            </select>
        </div>

                            <div className='inputComponentActiveIntv'>
                                <label htmlFor="">Rate<span>*</span></label>
                                <input type="text"
                                    name="rate"
                                    placeholder="Enter Rate"
                                    value={formData.rate}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="inputComponentActiveIntv">
                                <label htmlFor="">Submission Date<span>*</span></label>
                                <DatePicker
                                    selected={formData.submission_date ? new Date(formData.submission_date) : null}
                                    onChange={handleDateChange}
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="mm/dd/yyyy"
                                    name="submission_date"
                                    customInput={<CustomInput />}
                                />
                            </div>

                            <div className='inputComponentActiveIntv'>
                                <label htmlFor="">Submission Status<span>*</span></label>
                                <select
                                    className="company-data"
                                    name="submission_status"
                                    value={formData.submission_status}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Option</option>
                                    <option value="submitted to vendor">submitted to vendor</option>
                                    <option value="under review">under review</option>
                                    <option value="Shortlisted">Shortlisted</option>
                                    <option value="Not Shortlisted">Not Shortlisted</option>
                                    <option value="Technical Screening">Technical Screening</option>
                                    <option value="Interview Round 1">Interview Round 1</option>
                                    <option value="Interview Round 2">Interview Round 2</option>
                                    <option value="Interview Round 3">Interview Round 3</option>
                                    <option value="Interview Rejected">Interview Rejected</option>
                                </select>
                            </div>


                            <div className='inputComponentActiveIntv'>
                                <label htmlFor="">Technology</label>
                                <input type="text"
                                    name="technology"
                                    value={formData.technology}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='inputComponentActiveIntv'>
                                <label htmlFor="">Vendor<span>*</span></label>
                                <input type="text"
                                    name="to_email"
                                    value={formData.to_email}
                                    onChange={handleChange}
                                />
                            </div>

                        </div>
                    </section>

                    <section>
                        <div className='legendHeading-activeIntv'>
                            <img src={Others} alt="" srcset="" />
                            <h5>Interview Details</h5>
                            <span className='divider-intv'></span>
                        </div>
                        <div className='gridActiveIntv'>
                            {/* <div className='inputComponentActiveIntv'> */}

                            <div className='inputComponentActiveIntv'>
                                <label htmlFor="">Client Name<span>*</span></label>
                                <input type="text"
                                    name="client_name"
                                    value={formData.client_name}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="inputComponentActiveIntv">
                                <label htmlFor="">Interview Date Time</label>
                                <DatePicker
                                    selected={
                                        formData.interview_date_time
                                            ? new Date(formData.interview_date_time)
                                            : null
                                    }
                                    onChange={handleDateChangeMarketing}
                                    showTimeSelect // Enable time selection
                                    timeFormat="HH:mm" // Time format for the time picker
                                    timeIntervals={15} // Intervals for time selection (15 mins here)
                                    dateFormat="MM/dd/yyyy HH:mm:ss" // Display format
                                    placeholderText="mm/dd/yyyy hh:mm:ss"
                                    name="interview_date_time"
                                    customInput={<CustomInputMarketing />}
                                />
                            </div>

                            <div className='inputComponentActiveIntv'>
                                <label htmlFor="">Interview Timezone</label>
                                <select
                                    name="interview_timezone"
                                    value={formData.interview_timezone}
                                    onChange={handleChange}
                                >
                                    <option value="">Select TimeZone</option>
                                    <option value="CST">CST</option>
                                    <option value="EST">EST</option>
                                    <option value="MST">MST</option>
                                </select>
                            </div>
                        </div>
                    </section>

                    <section>
                        {/* <div className='legendHeading-activeIntv'>
                            <img src={Others} alt="" srcset="" />
                            <h5>Other</h5>
                            <span className='divider'></span>
                        </div> */}
                        <div className='gridActiveIntv'>
                            <div className='inputContent'>
                                <label htmlFor="">Comment</label>
                                <textarea
                                    id="comments"
                                    name="comments" // Add a name attribute to match state
                                    rows="5" // Correct the attribute from `row` to `rows`
                                    className="textarea-reason"
                                    value={formData.comments}
                                    onChange={handleChange}
                                ></textarea>
                            </div>
                        </div>

                        <div className='gridActiveIntv'>

                            <div className='inputContent1'>
                                <label htmlFor="">Interview Panel Member</label>
                                <textarea type="text"
                                    id="interview_panel_members"
                                    name="interview_panel_members"
                                    rows="5" // Correct the attribute from `row` to `rows`
                                    className="textarea-reason"
                                    value={formData.interview_panel_members}
                                    onChange={handleChange}
                                ></textarea>
                            </div>
                        </div>
                    </section>
                </div>
                <button className="saveBtnIntv"
                    onClick={handleActiveIntv}
                >
                    <img src={saveTick} alt="saveTickIcon" />
                    Save Changes
                </button>
            </div>
        </div>

    )
}

export default EditActiveInterview
import React, { useState, useEffect, Component } from 'react';
import Dashnav from '../../components/dashnav';
import TopUser from "../../images/top-user.svg";
import Profile from "../../images/your-profile.svg";
import "./RecruiterDetails.css";
import "../dashboard/style.css";
import { ReactComponent as RightTickIcon } from "../../images/icon-right-tick.svg";
import { ReactComponent as EditIconButton } from "../../images/icon-edit.svg";
import { ReactComponent as DeleteIcon } from "../../images/icon-delete.svg";
import { useNavigate } from "react-router-dom";
import AddIcon from "../../images/add-btn-icon.svg";
import { useGlobalContext } from '../../globalContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as CandidateNameIcon } from "../../images/candidate-name.svg";
import { ReactComponent as NameUserIcon } from "../../images/name-user-icon.svg";
import { ReactComponent as Female } from "../../images/female.svg";
import { ReactComponent as Others } from "../../images/otherGender.svg";


const RecruiterDetails = () => {

    const [candidates, setCandidates] = useState([]);
    const navigate = useNavigate();
    const { searchRecName, setSearchRecName } = useGlobalContext('');
    const [filteredCandidates, setFilteredCandidates] = useState([]);
    const [dataDelete, setDataDelete] = useState(false);
    const { filterByTeam, setFilterByTeam } = useGlobalContext('');
    const {filterByStatus, setFilterByStatus} = useGlobalContext("");
    const {filterByManager, setFilterByManager} = useGlobalContext("");
    const { setSideBarLeft} = useGlobalContext();
    let storedUser = JSON.parse(localStorage.getItem('user')) || "";
    console.log("storedUser: ", storedUser);

    useEffect(() => {
        setCandidates([]);
        if (storedUser) {
            fetch('https://us-east1-recruiterportal.cloudfunctions.net/Retrieve_Recruiter_Details_v3', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'emailid' : storedUser.email,
                    "operation": "retrieve"
                })
            })
                .then(response => response.json())
                .then(data => {
                    // Transform the data into a suitable format
                    const transformedCandidates = data.data.map((recruiter) => {
                        return recruiter.details.map((detail) => ({
                            id: detail.id,
                            gender:detail.gender,
                            name: detail.recruiter_name,
                            email: detail.recruiter_email,
                            organization: detail.recruiter_organization,
                            status: detail.recruiter_status,
                            team: detail.recruiter_team,
                            manager: detail.recruiter_manager,
                        }));
                    }).flat(); // Flatten the nested array from `details`

                    console.log("Transformed candidates: ", transformedCandidates);

                    setCandidates(transformedCandidates);
                    setDataDelete(false);

                })
                .catch(error => console.error('Error fetching candidates:', error));
        }

        return () => {
            setCandidates([]);
        }
    }, [dataDelete]);


    const openModal = (id) => {
        console.log("indise fun");
        navigate(`/editDetails/?id=${id}`);
    };

    function candidateAddButton() {
        navigate("/addDetails");
    }


    useEffect(() => {
        let filtered = candidates;

        // Search by name
        if (searchRecName) {
            filtered = filtered.filter(candidate =>
                candidate.name.toLowerCase().includes(searchRecName.toLowerCase()) ||
                candidate.name.toLowerCase().includes(searchRecName.toLowerCase())
            );
        }

        // Filter by team
        if (filterByTeam) {
            filtered = filtered.filter(candidate => {
                return candidate.team === filterByTeam;
            });
        }

        // Filter by Current status
        if (filterByStatus) {
            filtered = filtered.filter(candidate =>
                candidate.status === filterByStatus
            );
        }


        // Filter by manager
        if (filterByManager) {
            filtered = filtered.filter(candidate => {
                return candidate.manager === filterByManager;
            });
        }

        setFilteredCandidates(filtered);
    }, [searchRecName, filterByTeam, filterByStatus, filterByManager, candidates]);


    const [currentPage, setCurrentPage] = useState(1);
    const jobsPerPage = 10;

    const totalPages = Math.ceil(filteredCandidates.length / jobsPerPage);
    const indexOfLastJob = currentPage * jobsPerPage;
    const indexOfFirstJob = indexOfLastJob - jobsPerPage;

    const currentJobs = filteredCandidates.slice(indexOfFirstJob, indexOfLastJob);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handlePageInputChange = (e) => {
        const pageNum = Number(e.target.value);
        if (pageNum >= 1 && pageNum <= totalPages) {
            setCurrentPage(pageNum);
        }
    };

    useEffect(() => {
        handlePageChange(currentPage);
    }, [currentPage]);


    const deleteCandidate = async (candidate) => {
        if(storedUser){
        if (window.confirm('Are you sure you want to delete')) {
            try {
                const response = await fetch(`https://us-east1-recruiterportal.cloudfunctions.net/Retrieve_Recruiter_Details_v3`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(
                        {
                            "emailid" : storedUser.email,
                            "operation": "delete",
                            "id": `${candidate}`
                        }
                    )
                })
                if (response.ok) {
                    // alert candidate is deleted and list is updated
                    toast.success('Recruiter Deleted successfully', {
                        draggable: "true",
                        // autoClose: false,
                        autoClose: 2000,
                        // position: "center",
                        className: "toast-center",
                    });
                    setDataDelete(true);
                }
                else {
                    throw new Error('failed to delete candidates');
                }
            }
            catch (error) {
                console.error('Error deleting candidate:' + error.message);
            }
        }
    }
    }

    return (
        <div>
            <div className="main-dash row-flex">
                <Dashnav />
                <section className="w-100">
                    <div className="top-section row-flex">
                        <img src={TopUser} alt="" />
                        <p className="title-left">Recruiter Details</p>
                        <button className="profile-button-right">
                            <img src={Profile} alt="" className='profile' />
                            Your Profile</button>
                            <div className=" sidenav-icon row-flex" onClick={setSideBarLeft}>
                                <svg class="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M17 8L21 12L17 16M3 12H13M3 6H13M3 18H13"></path>
                                </svg>
                            </div>
                    </div>

                    <div className="filters-data row-flex">
                        <div className="filters-left-data">
                            <input
                                type="text"
                                placeholder="Search by name"
                                value={searchRecName}
                                onChange={(e) => setSearchRecName(e.target.value)}
                            />

                            <select
                                value={filterByTeam}
                                onChange={(e) => setFilterByTeam(e.target.value)}
                            >
                                <option value="">Filter By Team</option>
                                <option value="Team A">Team A</option>
                                <option value="Team B">Team B</option>
                                <option value="Team C">Team C</option>
                                <option value="Team BDE">Team BDE</option>
                            </select>
        
                            <select
                                    type="text"
                                    placeholder="current Status"
                                    value={filterByStatus}
                                    onChange={(e) => setFilterByStatus(e.target.value)}
                                >
                                    <option value="">Filter by Status</option>
                                    {candidates.length && [...new Set(candidates.map(item => item.status))]
                                        .map((statusActive, index) => (
                                            <option key={index} value={statusActive}>{statusActive}</option>
                                        ))}
                                </select>

                                <select
                                value={filterByManager}
                                onChange={(e) => setFilterByManager(e.target.value)}
                            >
                                <option value="">Filter By Manager</option>
                                <option value="mani1@4spheresolutions.com">mani1@4spheresolutions.com</option>
                                <option value="amit@4spheresolutions.com">amit@4spheresolutions.com</option>
                                <option value="mat@4spheresolutions.com">mat@4spheresolutions.com</option>
                            </select>
                        </div>



                        <div className="filters-right-data">
                            <button><img src={AddIcon} onClick={candidateAddButton} /></button>
                            <div>Candidates: {candidates.length}</div>
                        </div>
                    </div>

                    <div className='mainContent'>
                        <div className="table-container">
                            <table className="dynamic-table-data">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className='alignCenter'>
                                                <CandidateNameIcon />
                                                Name
                                            </div>
                                        </th>
                                        <th>
                                            <div className='alignCenter'>
                                                {/* <EmailIcon /> */}
                                                Email
                                            </div>
                                        </th>
                                        <th>
                                            <div className='alignCenter team-col'>
                                                {/* <VisaIcon /> */}
                                                Team
                                            </div>
                                        </th>

                                        <th>
                                            <div className='alignCenter'>
                                                {/* <VisaIcon /> */}
                                                Manager
                                            </div>
                                        </th>

                                        <th>
                                            <div className='alignCenter org-col'>
                                                {/* <CompanyIcon /> */}
                                                Organization
                                            </div>
                                        </th>
                                        <th>
                                            <div className='alignCenter'>
                                                {/* <StatusIcon /> */}
                                                Status
                                            </div>
                                        </th>

                                        <th>
                                            <div className='alignCenter act-col'>
                                                {/* <ActionIcon /> */}
                                                Action
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Map through the candidates */}
                                    {currentJobs.length > 0 ? (
                                        currentJobs.map((candidate, index) => (
                                            <tr key={index}>
                                                {/* <td>{candidate.id}</td> */}
                                                {/* <td>{candidate.name}</td> */}
                                                <td
                                                    className={`status-change-statuschange common-cls${candidate.gender === "Male"
                                                        ? "Male"
                                                        : candidate.gender === "Female"
                                                            ? "Female"
                                                            : "Other"
                                                        }`}
                                                >
                                                    <div className="alignCenter common-cls">

                                                        {candidate.gender === "Male" ? (
                                                            <NameUserIcon />
                                                        ) : candidate.gender === "Female" ? (
                                                            <Female />
                                                        ) : (
                                                            <Others />
                                                        )}

                                                        <span>
                                                            {candidate.name}
                                                        </span>
                                                </div>
                                                </td>
                                                <td>{candidate.email}</td>
                                                <td><span className='change-team'>{candidate.team}</span></td>
                                                <td>{candidate.manager}</td>
                                                <td>{candidate.organization}</td>
                                                {/* <td>{candidate.status}</td> */}

                                                <td
                                                    className={` status-change-statuschange ${candidate.status === "Active"
                                                        ? "active"
                                                        : candidate.status === "Inactive"
                                                            ? "inactive"
                                                            : "Unknown"
                                                        }`}
                                                >
                                                    <div className={`status-change ${candidate.status}`}>
                                                        <span>
                                                            {candidate.status || " "}
                                                        </span>
                                                        {candidate.status === "Active" ? (
                                                            <RightTickIcon />
                                                        ) : candidate.status === "Inactive" ? (
                                                            <RightTickIcon />
                                                        ) : (
                                                            <i className=" "></i>
                                                        )}
                                                    </div>

                                                </td>


                                                <td>
                                                    <button
                                                        onClick={() => openModal(candidate.id)}
                                                        className="button-icon">
                                                        {/* icon for Edit */}
                                                        <EditIconButton />

                                                    </button>
                                                    <button
                                                        onClick={() => deleteCandidate(candidate.id)}
                                                        className="button-icon"
                                                    >
                                                        {/* icon for delete */}
                                                        <DeleteIcon />

                                                    </button>
                                                </td>

                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="7">No data available</td>
                                        </tr>
                                    )}
                                </tbody>


                                <div className='row-flex navigation' style={{ marginTop: '10px' }}>
                                    <button className='left nav' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="10.3264" cy="10.5759" r="9.92407" fill="#D6D1F3" />
                                            <path d="M12.804 10.9292C12.9992 10.734 12.9992 10.4174 12.804 10.2221L9.62198 7.04015C9.42672 6.84489 9.11014 6.84489 8.91488 7.04015C8.71961 7.23541 8.71961 7.55199 8.91488 7.74726L11.7433 10.5757L8.91488 13.4041C8.71961 13.5994 8.71961 13.916 8.91488 14.1112C9.11014 14.3065 9.42672 14.3065 9.62198 14.1112L12.804 10.9292ZM12.1279 11.0757L12.4504 11.0757L12.4504 10.0757L12.1279 10.0757L12.1279 11.0757Z" fill="#341FA8" />
                                        </svg>

                                    </button>
                                    <span style={{ margin: '0 10px' }} className='text'>
                                        Page {currentPage} of {totalPages}
                                    </span>
                                    <button className='nav' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="10.3264" cy="10.5759" r="9.92407" fill="#D6D1F3" />
                                            <path d="M12.804 10.9292C12.9992 10.734 12.9992 10.4174 12.804 10.2221L9.62198 7.04015C9.42672 6.84489 9.11014 6.84489 8.91488 7.04015C8.71961 7.23541 8.71961 7.55199 8.91488 7.74726L11.7433 10.5757L8.91488 13.4041C8.71961 13.5994 8.71961 13.916 8.91488 14.1112C9.11014 14.3065 9.42672 14.3065 9.62198 14.1112L12.804 10.9292ZM12.1279 11.0757L12.4504 11.0757L12.4504 10.0757L12.1279 10.0757L12.1279 11.0757Z" fill="#341FA8" />
                                        </svg>
                                    </button>
                                </div>

                                {/* Direct Page Input */}
                                {/* <div style={{ marginTop: '10px' }}>
                                    <label>
                                    Go to page:{' '}
                                    <input
                                        type="number"
                                        min="1"
                                        max={totalPages}
                                        value={currentPage}
                                        onChange={handlePageInputChange}
                                    />
                                    </label>
                                </div> */}

                            </table>
                            {/* Pagination Controls */}

                            {/* <div className='row-flex navigation' style={{ marginTop: '10px' }}>
                                    <button className='left nav' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="10.3264" cy="10.5759" r="9.92407" fill="#D6D1F3" />
                                            <path d="M12.804 10.9292C12.9992 10.734 12.9992 10.4174 12.804 10.2221L9.62198 7.04015C9.42672 6.84489 9.11014 6.84489 8.91488 7.04015C8.71961 7.23541 8.71961 7.55199 8.91488 7.74726L11.7433 10.5757L8.91488 13.4041C8.71961 13.5994 8.71961 13.916 8.91488 14.1112C9.11014 14.3065 9.42672 14.3065 9.62198 14.1112L12.804 10.9292ZM12.1279 11.0757L12.4504 11.0757L12.4504 10.0757L12.1279 10.0757L12.1279 11.0757Z" fill="#341FA8" />
                                        </svg>

                                    </button>
                                    <span style={{ margin: '0 10px' }} className='text'>
                                        Page {currentPage} of {totalPages}
                                    </span>
                                    <button className='nav' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="10.3264" cy="10.5759" r="9.92407" fill="#D6D1F3" />
                                            <path d="M12.804 10.9292C12.9992 10.734 12.9992 10.4174 12.804 10.2221L9.62198 7.04015C9.42672 6.84489 9.11014 6.84489 8.91488 7.04015C8.71961 7.23541 8.71961 7.55199 8.91488 7.74726L11.7433 10.5757L8.91488 13.4041C8.71961 13.5994 8.71961 13.916 8.91488 14.1112C9.11014 14.3065 9.42672 14.3065 9.62198 14.1112L12.804 10.9292ZM12.1279 11.0757L12.4504 11.0757L12.4504 10.0757L12.1279 10.0757L12.1279 11.0757Z" fill="#341FA8" />
                                        </svg>
                                    </button>
                                </div> */}
                        </div>
                    </div>


                </section>
            </div>
        </div>
    )
}

export default RecruiterDetails
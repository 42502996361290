import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from '../authContext';
import "./loginstyle.css";
import BgImage from "./bgimage.jpg"
import LoaderIconW from "../components/loader.svg";


const LoginSignUp = () => {
    const { isLoggedIn, login} = useAuth();
    const navigate = useNavigate();
    // Login form fields
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [loginError, setLoginError] = useState("");
    const [passwordVisible, setPasswordVisible] = useState("");
    const [activeTab, setActiveTab] = useState('tab1');
    const [emailError, setEmailError] = useState("");  // Added state for email error
    const [phoneError, setPhoneError] = useState("");  // Phone number error state
    const [countryCode, setCountryCode] = useState("+91"); // Default to India (+91)
    const [passwordError, setPasswordError] = useState(""); // Password match error state
    const [signInAnim, setSignInAnim] = useState("");
    const [isLoading, setIsLoading] = useState(false); 
    
    
    const SignUpAuth = async () => {
        if (emailError || phoneError || passwordError) {
            alert("Please fix the errors before submitting.");
            return;
        }
    
        
        const formData = {
            action: 'sign-up',
            first_name: firstName,
            last_name: lastName,
            username: username,
            email: emailAddress,
            password: password,
            mobile_no: contactNumber,
            country_code: countryCode,
            app_name:"recruitersportal"

        };
    
        try {
            const response = await fetch("https://us-east1-foursssolutions.cloudfunctions.net/user_authentication_api", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            });
    
            if (response.ok) {
                alert("Signup successful!");
                setActiveTab('tab1');  // Switch back to login tab
                // RegisterUser();
            } else {
                alert("Signup failed. Please try again.");
            }
        } catch (error) {
            console.error("Error during signup:", error);
            alert("An error occurred. Please try again later.");
        }
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            if (isLoggedIn === true) {
                navigate("/dashboard");
            }
            console.log(isLoggedIn);
        }, 100); 
        return () => clearTimeout(timer);
    }, [isLoggedIn, navigate]);
    
    const RegisterUser = async () => {
        const dataRecruitPortal = {
            query: `INSERT INTO foursphere_recruiters.recruiterportal_users (
                first_name, last_name, username, email, password, mobile_no, country_code
            ) VALUES (
                '${firstName}', '${lastName}', '${username}', '${emailAddress}', '${password}', '${contactNumber}', '${countryCode}'
            );`
        };

        try {
            const response = await fetch("https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(dataRecruitPortal)
            });
    
            if (response.ok) {
                console.log("Signup successful!");
            } else {
                alert("Signup failed. Please try again.");
            }
        } catch (error) {
            console.error("Error during signup:", error);
            alert("An error occurred. Please try again later.");
        }
    };
    
    
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setLoginError("");
    };

    //email validation is not being used as we are allowing different organizations.
    // const validateEmail = (email) => {
    //     const domain = "@4spheresolutions.com";
    //     if (email.endsWith(domain)) {
    //         setEmailError("");
    //     } else {
    //         setEmailError("Please ensure the email address ends with @4spheresolutions.com.");
    //     }
    // };

    const validatePhoneNumber = (phone) => {
        const phoneDigits = phone.replace(/\D/g, ''); // Remove non-digit characters
        if (phoneDigits.length === 10) {
            setPhoneError("");
        } else {
            setPhoneError("Please enter a valid 10-digit phone number.");
        }
    };
useEffect(() => {
    const validatePasswords = () => {
        if (password != confirmPassword) {
            setPasswordError("Passwords do not match.");
            console.log(password + "password" + "confirm" + confirmPassword);
        } else {
            setPasswordError("");
        }
    };
    validatePasswords();
}, [password, confirmPassword]);
    const validatePasswords = () => {
        if (password != confirmPassword) {
            setPasswordError("Passwords do not match.");
            console.log(password + "password" + "confirm" + confirmPassword);
        } else {
            setPasswordError("");
        }
    };
    const SignIn = async () => {
        setIsLoading(true); 

        const formData = {
            action: 'sign-in',
            username: loginEmail,
            password: loginPassword,
            app_name: 'recruitersportal',
        };

        try {
            const response = await fetch('https://us-east1-foursssolutions.cloudfunctions.net/user_authentication_api', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Invalid credentials');
            }
            setSignInAnim(true);  // Start animation

            const data = await response.json();
            login(data);  // Pass the entire user data to the login function
            navigate('/dashboard');  // Navigate to the dashboard
        } catch (error) {
            setLoginError(error.message);
            setSignInAnim(false);  // Stop animation if error occurs
        }   finally {
            setIsLoading(false); // Hide loading spinner when the request is done
        }
    };
    

    return (
        <div className="login-SignUp">
            {isLoading && (
                <div className="MainLoading">
                    <div className="loading-container">
                        <div className="spinner"></div>
                        <p>Loading...</p>
                    </div>
                </div>
            )}
            <div className="login-inner">
                <img className='bgImage' src={BgImage} alt="background-image" />
                <div className={`log-sign-frm ${activeTab === 'tab1' ? 'active' : ''}`} id='one'>
                    <h3 className="login-title">
                        Log in
                    </h3>
                    <h4 className="subtitle">
                        Recruitment Portal
                    </h4>
                    <div className="login-frm col-flex">
                        <div className="input col-flex">
                            <span className="head">Username</span>
                            <input 
                                type="text" 
                                className="login-input"
                                value={loginEmail}
                                onChange={(e) => setLoginEmail(e.target.value.replace(/\s+/g, ''))}
                                placeholder="Enter Username"
                            />
                        </div>
                        <div className="input col-flex">
                            <span className="head">Password</span>
                            <div className="password-input-wrapper">
                                <input 
                                    type={passwordVisible ? "text":"password"} 
                                    className="login-input full-width"
                                    value={loginPassword}
                                    onChange={(e) => setLoginPassword(e.target.value)}
                                    placeholder="Enter Password"
                                />
                                <button
                                        type="button"
                                        className="password-toggle"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {passwordVisible ? '👁️' : '👁️‍🗨️'}
                                </button>
                            </div>
                        </div>
                        {loginError}
                        <Link to="/resetpassword" className="forgotLink" >Forgot Password</Link>
                    </div>
                <div className="button-row row-flex">
                <button className="login-button" onClick={SignIn}>
                            Log in
                            <img src={LoaderIconW} alt="" className={`loader ${signInAnim=="active"?"active":""}`} />
                        </button>
                        <button className="signup-button" onClick={() => handleTabClick('tab2')}>
                            Sign Up
                        </button>
                </div>
                </div>
                <div className={`log-sign-frm ${activeTab === 'tab2' ? 'active' : ''}`} id='two'>
                <h3 className="login-title">
                        Sign up
                    </h3>
                    <h4 className="subtitle">
                        Get Registered
                    </h4>
                    <div className="login-frm col-flex">
                        <div className="multi-field row-flex">
                            <div className="col-flex">
                                <div className="input col-flex">
                                    <span className="head">First Name</span>
                                    <input 
                                        type="text" 
                                        className="login-input"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        placeholder="First Name"
                                    />
                                </div>
                                <div className="input col-flex">
                                    <span className="head">Last Name</span>
                                    <input 
                                        type="text" 
                                        className="login-input"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        placeholder="Enter Lastname"
                                    />
                                </div>
                                <div className="input col-flex">
                                    <span className="head">Email</span>
                                    <input 
                                        type="text" 
                                        className="login-input"
                                        value={emailAddress}
                                        onChange={(e) => {
                                            setEmailAddress(e.target.value.replace(/\s+/g, ''));
                                        }}
                                        placeholder="Email Address"
                                    />
                                </div>
                                
                            </div>
                            <div className="col-flex">
                                <div className="input col-flex">
                                    <span className="head">User Name</span>
                                    <input 
                                        type="text" 
                                        className="login-input"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value.replace(/\s+/g, ''))}
                                        placeholder="Enter User name"
                                    />
                                </div>
                                <div className="input col-flex">
                                    <span className="head">Password</span>
                                    <div className="password-input-wrapper">
                                        <input
                                            type={passwordVisible ? "text" : "password"}
                                            className="login-input full-width"
                                            value={password}
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                            }}
                                            placeholder="Enter Password"
                                        />
                                        <button
                                                type="button"
                                                className="password-toggle"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {passwordVisible ? '👁️' : '👁️‍🗨️'}
                                        </button>
                                    </div>
                                </div>
                                <div className="input col-flex">
                                    <span className="head">Re-enter Password</span>
                                    <div className="password-input-wrapper">
                                        <input
                                            type={passwordVisible ? "text" : "password"}
                                            className="login-input full-width"
                                            value={confirmPassword}
                                            onChange={(e) => {
                                                setConfirmPassword(e.target.value);
                                            }}
                                            placeholder="Confirm Password"
                                        />
                                        <button
                                                type="button"
                                                className="password-toggle"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {passwordVisible ? '👁️' : '👁️‍🗨️'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="contact-bot">
                            <div className="input col-flex">
                                    <span className="head">Phone Number</span>
                                    <div className="row-flex">
                                        <select
                                            className="country-code-select"
                                            value={countryCode}
                                            onChange={(e) => setCountryCode(e.target.value)}
                                        >
                                            <option value="+91">🇮🇳 +91 (India)</option>
                                            <option value="+1">🇺🇸 +1 (USA)</option>
                                        </select>
                                        <input
                                            type="tel"
                                            className="login-input"
                                            value={contactNumber}
                                            onChange={(e) => {
                                                setContactNumber(e.target.value);
                                                validatePhoneNumber(e.target.value);
                                            }}
                                            placeholder="1234567890"
                                            maxLength="10"
                                        />
                                    </div>
                            </div>
                        </div>
                        
                    </div>
                    {emailError && <p className="error">{emailError}</p>}  {/* Display email error */}
                    {phoneError && <p className="error">{phoneError}</p>}  {/* Display email error */}
                    {passwordError && <p className="error">{passwordError}</p>}  {/* Display email error */}
                    <div className="row-flex button-row">
                        <button className="signup-button" onClick={SignUpAuth}>
                            SIGN UP
                        </button>
                        <button className="login-button" onClick={() => handleTabClick('tab1')}>
                            Go To Login
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginSignUp;
